<template>
  <div class="page page-xranking-all-cv">
    <PageHeader
      back="/xranking/cvs/"
      :icon="$t('CV.ICON')"
      :title="`${$t('CV.TITLE')} - ${fullName}`"
    />

    <div class="page-xranking-all-cv__action d-flex justify-end mr-5 mt-5">
      <v-btn
        v-for="({ name, show }) in tabs"
        class="page-xranking-all-cv__action-button mr-5"
        :class="{
          'active': show,
        }"
        :key="name"
        @click="switchTab(name)"
      >
         {{ name }}
      </v-btn>
    </div>
    
    <template>
      <component :is="itemComponents[tab]" type="cv" />
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import ListedView from '@/components/ListedView';
import OriginalView from '@/components/OriginalView';
import { mapActions, mapGetters } from 'vuex';

const itemComponents = Object.freeze({
  original: 'original-view',
  listed: 'listed-view',
});

export default {
  name: 'CVParsing',
  components: {
    PageHeader,
    'listed-view': ListedView,
    'original-view': OriginalView,
  },
  data() {
    return {
      tab: 'listed',
      tabs: [
        { name: 'original', show: false },
        { name: 'listed', show: true },
      ],
      itemComponents,
      fullName: '',
    };
  },
  mounted() {
    this.loadCV();
  },
  computed: {
    ...mapGetters({
      cv: 'cv/getCV',
    }),
    cvId() {
      return this.$route.params.id;
    },
    currentCv() {
      return this.cv;
    },
  },
  watch: {
    cv() {
      this.fullName = this.$store.getters['cv/getCV'].fullName;
    },
  },
  methods: {
    ...mapActions({
      addCV: 'cv/addCV',
    }),

    switchTab(n) {
      const newTabs = this.tabs.map(({ name })=> {
        if(name === n) {
          return { name, show: true };
        }

        return { name, show: false };
      });

      this.tabs = newTabs;
      this.tab = n;
    },
    
    loadCV() {
      this.$services.xranking_ra
        .v2_cvs.getById(this.cvId)
        .then(({ data }) => {
          const { cv } = data;
          this.addCV(cv);
        });
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--has-bg.page-xranking-all-cv__action-button {
  background-color: #fff;
  color: #201F35;

  &:hover {
    background-color: #201F35;
    color: #fff;
  }
}

.theme--light.v-btn.v-btn--has-bg.page-xranking-all-cv__action-button.active {
  background-color: #201F35;

  .v-btn__content {
    color: #fff;
  }
}
</style>
