<template>
  <header class="page-header">
    <v-toolbar
      :dark="!!dark"
      flat
      color="transparent"
      height="92px"
      class="pa-0"
    >
      <div
        :class="['page-header-content d-flex flex-column', 'justify-center']"
      >
        <div class="page-header-subtitle" v-if="subtitle">
          <span>{{ subtitle }}</span>
        </div>
        <div class="page-header-title text-h5 mb-2">
          <v-btn
            :dark="!!dark"
            v-if="back"
            icon
            outlined
            small
            class="back-btn ma-0 mr-2"
            @click="
              typeof back === 'string' ? $router.push(back) : $router.go(back)
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-icon
            :dark="!!dark"
            size="24"
            class="mr-4 mb-1"
            :color="!!dark ? 'white' : 'primary'"
            v-if="icon"
          >
            {{ icon }}
          </v-icon>
          <span class="page-header-title__name">{{ title }} <slot name="detail-info" /></span>
        </div>
        <div class="page-header-nav grey--text" v-if="!!$slots.nav">
          <slot name="nav" />
        </div>
      </div>
      <v-spacer />
      <slot />
    </v-toolbar>
    <!-- <div class="pr-4 pl-4" v-if="!!$slots.navmenu"><v-divider class="primary" /></div> -->
    <div class="toolbar-separator primary ml-4 mr-4 page-header-separator" />
    <div class="page-header-navmenu ml-4" v-if="!!$slots.navmenu">
      <slot name="navmenu" />
    </div>
  </header>
</template>

<style>
.page-header .v-toolbar__content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.page-header-subtitle {
  margin-top: 10px;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.page-header-subtitle + .page-header-title {
  line-height: 32px !important;
}

.page-header-content {
  height: 100%;
  width: 100%;
  padding-right: 20px;
  margin-bottom: -4px;
  margin-top: -4px;
}
.page-header-title {
  line-height: 32px !important;
}

.page-header-navmenu {
  margin-top: -2px;
  margin-right: 16px; 
}

.page-header .v-tabs-slider-wrapper {
  top: 0px;
}

.page-header-nav {
  min-height: 26px;
}
</style>

<script>
export default {
  name: "PageHeader",
  props: ["icon", "title", "subtitle", "count", "back", "dark"],
};
</script>
